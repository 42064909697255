const Footer = () => {
  return (
    <div className="footer pb-[10px] flex justify-center relative text-text-color mt-[20px]">
      <div className="footer-container flex items-baseline container justify-around relative text-fs-footer">
        <div className="copyright-col text-center">
          Copyright &#169; {new Date().getFullYear()}{" "}
          <a href="https://vyral24.com/" className="underline">
            VYRAL24 ADVERTISING AND MARKETING SERVICES PVT. LTD.
          </a>
        </div>
        <div className="footer-links flex xsm:gap-1 sm:gap-6 px-5 sm:px-3 md:px-0">
          <div className="footer-sub-container flex-col">
            <span className="font-avertastd-semibold">Contact Us</span>
            <div className="max-w-[300px]">
              <p>Email Address: team@marketingwithram</p>
              <p>Support Mobile:+91-9205252399</p>
              <p>
                Corporate Office: Magnum Tower 1, 8th Floor, Golf Course Ext Rd, Sector 58, Gurugram, Haryana, 122011
              </p>
            </div>
          </div>
          <div className="footer-sub-container font-avertastd-semibold">
            <a href="/refund-policy" target="_blank" className="crc-link underline" rel="noreferrer">
              Cancellation and Refund Policy
            </a>
            {/* <a href="/shipping-policy" target="_blank" className="sd-link underline" rel="noreferrer">
              Shipping and Delivery
            </a> */}
            <a href="/privacy-policy" target="_blank" className="pp-link underline relative" rel="noreferrer">
              Privacy Policy
            </a>
            <a href="/terms-and-condition" target="_blank" className="tnc-link underline" rel="noreferrer">
              Terms and Conditions
            </a>
            <a href="/contact-us" target="_blank" className="tnc-link underline" rel="noreferrer">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
