import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Confetti from "react-confetti";

import "./ContactModal.css";

const Modal = ({ open, onClose }) => {
  const formRef = useRef();
  const [page, setPage] = useState(1);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [messageSent, setMessageSent] = useState("Submit");
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    workEmail: "",
    monthlyRevenue: "",
    adBudgetConfirmation: "",
    retainerConfirmation: "",
    runningAds: "",
    marketingBudget: "",
    marketingInvestment: "",
    customerAcquisition: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => setPage((prevPage) => prevPage + 1);
  const handleBack = () => setPage((prevPage) => prevPage - 1);
  const handleEmailCheck = () => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return formData.workEmail === "" ? false : emailRegex.test(formData.workEmail);
  };

  const handleNumberCheck = () => {
    return formData.phoneNumber === ""
      ? false
      : formData.phoneNumber.length === 10 && /^\d+$/.test(formData.phoneNumber);
  };

  const handleSubmit = async function (e) {
    setMessageSent("Loadinggg...");
    e.preventDefault();
    const url =
      "https://script.google.com/macros/s/AKfycbxwMO7dawgi3vH5J0f-J0AUljSA9xNljLXlSF6g2DV9NxyJuurBpgC7PE0ARY7sNOxw/exec";

    const formDataSend = new URLSearchParams({
      name: formData.name,
      workEmail: formData.workEmail,
      phoneNumber: formData.phoneNumber,
      monthlyRevenue: formData.monthlyRevenue,
      adBudgetConfirmation: formData.adBudgetConfirmation,
      retainerConfirmation: formData.retainerConfirmation,
      runningAds: formData.runningAds,
      marketingBudget: formData.marketingBudget,
      marketingInvestment: formData.marketingInvestment,
      customerAcquisition: formData.customerAcquisition,
    });

    try {
      // Make a POST request using axios

      const response = await axios.post(url, formDataSend, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Handle the response as needed
      setFormData({
        name: "",
        phoneNumber: "",
        workEmail: "",
        monthlyRevenue: "",
        adBudgetConfirmation: "",
        retainerConfirmation: "",
        runningAds: "",
        marketingBudget: "",
        marketingInvestment: "",
        customerAcquisition: "",
      });

      if (response?.status === 200) {
        setMessageSent("Message Sent!");
        setTimeout(() => {
          setMessageSent("Submit");
        }, 1000);
        setTimeout(() => {
          onClose();
          setPage(1);
        }, 300);

        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
          setTimeout(() => {
            window.location.href = "/book-meeting";
          }, 500);
        }, 5000);
        
      }
    } catch (error) {
      // Handle errors
      onClose();
      setPage(1);
      alert("Something bad happened ! Try again later !");

      setFormData({
        name: "",
        phoneNumber: "",
        workEmail: "",
        monthlyRevenue: "",
        adBudgetConfirmation: "",
        retainerConfirmation: "",
        runningAds: "",
        marketingBudget: "",
        marketingInvestment: "",
        customerAcquisition: "",
      });
    }
  };

  const checkPageValidity = () => {
    switch (page) {
      case 1:
        return (
          formData.name.trim() !== "" &&
          handleEmailCheck() &&
          handleNumberCheck() &&
          formData.monthlyRevenue.trim() !== ""
        );
      case 2:
        return formData.adBudgetConfirmation.trim() !== "" && formData.retainerConfirmation.trim() !== "";
      case 3:
        return formData.runningAds.trim() !== "" && formData.marketingBudget.trim() !== "";
      case 4:
        return formData.marketingInvestment.trim() !== "" && formData.customerAcquisition.trim() !== "";
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsNextEnabled(checkPageValidity());
    // eslint-disable-next-line
  }, [formData, page]);

  const renderOption = (name, value, label, description, colSpan) => (
    <label
      className={`flex items-center justify-between w-full p-4 mb-1 border rounded-lg cursor-pointer ${
        formData[name] === value ? "border-primary-dark  bg-green-50" : "border-gray-300"
      } ${colSpan}`}
    >
      <div>
        <span className="block text-lg font-avertastd-regular">{label}</span>
        <span className="block text-sm text-gray-500">{description}</span>
      </div>
      <div className="ml-2">
        <input
          type="radio"
          name={name}
          value={value}
          checked={formData[name] === value}
          onChange={handleChange}
          className="form-radio h-4 w-4 custom-radio"
        />
      </div>
    </label>
  );

  return (
    <div>
      {open && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[1000] flex justify-center items-start overflow-auto">
          <div className="form-container relative z-[1000] bg-background-color p-6 rounded-lg shadow-lg w-[500px] h-[760px] sm:h-[620px] flex flex-col">
            <AiOutlineCloseCircle
              onClick={() => {
                setTimeout(onClose, 300);
                document.querySelector(".form-container").classList.add("form-container-minimise");
              }}
              className="text-gray-600 absolute cursor-pointer right-4 top-4 text-2xl"
            />
            <div className="flex-grow flex flex-col">
              <h2 className="text-xl font-avertastd-bold mb-4 text-center">Please fill this form!</h2>
              <form
                ref={formRef}
                className="flex-grow flex flex-col space-y-4"
                action="https://docs.google.com/forms/u/5/d/e/1FAIpQLSf1Asznxf2ihFA_ns08KWFE64wJUH1wWq6atHD2Z-lqV895QA/formResponse"
              >
                {page === 1 && (
                  <>
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:outline-none"
                      placeholder="Name"
                      required
                      autoFocus
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <input
                      type="tel"
                      className="w-full p-2 border rounded focus:outline-none"
                      placeholder="Phone Number"
                      required
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      className="w-full p-2 border rounded focus:outline-none"
                      placeholder="Work Email"
                      required
                      name="workEmail"
                      value={formData.workEmail}
                      onChange={handleChange}
                    />
                    <div>
                      <label className="block text-gray-700 mb-2 font-avertastd-semibold">
                        What is your business current monthly revenue:
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        {renderOption(
                          "monthlyRevenue",
                          "Less than INR 5 Lacs per month",
                          "Less than INR 5 Lacs per month",
                          "",
                          ""
                        )}
                        {renderOption(
                          "monthlyRevenue",
                          "INR 5 - 10 Lacs per month",
                          "INR 5 - 10 Lacs per month",
                          "",
                          ""
                        )}
                        {renderOption(
                          "monthlyRevenue",
                          "INR 10 - 30 Lacs per month",
                          "INR 10 - 30 Lacs per month",
                          "",
                          ""
                        )}
                        {renderOption(
                          "monthlyRevenue",
                          "INR 30 - 50 Lacs per month",
                          "INR 30 - 50 Lacs per month",
                          "",
                          ""
                        )}
                        {renderOption(
                          "monthlyRevenue",
                          "Above 50 Lacs per month",
                          "Above 50 Lacs per month",
                          "",
                          "col-span-2"
                        )}
                      </div>
                    </div>
                  </>
                )}
                {page === 2 && (
                  <>
                    <div>
                      <label className="block text-gray-700 mb-2 font-avertastd-semibold">
                        To Ensure Best results we require a minimum ad budget of INR 2Lakhs/month from all clients:
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        {renderOption(
                          "adBudgetConfirmation",
                          "Yes, I can spend INR 2 Lakhs/month!",
                          "Yes, I can spend INR 2 Lakhs/month!",
                          "",
                          ""
                        )}
                        {renderOption(
                          "adBudgetConfirmation",
                          "No, that isn't my budget",
                          "No, that isn't my budget",
                          "",
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-2 font-avertastd-semibold">
                        Apart from the budget spent on the platforms like FB Ads & Google Ads, our Retainer Fees Starts
                        at INR 35,000 per month:
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        {renderOption(
                          "retainerConfirmation",
                          "Yes, I am willing to hire a good agency at this small price",
                          "Yes, I am willing to hire a good agency at this small price",
                          "",
                          ""
                        )}
                        {renderOption(
                          "retainerConfirmation",
                          "Your charges are too high",
                          "Your charges are too high",
                          "",
                          ""
                        )}
                        {renderOption(
                          "retainerConfirmation",
                          "No, I don't want to spend that much on Fees but can spend on Budget",
                          "No, I don't want to spend that much on Fees but can spend on Budget",
                          "",
                          "col-span-2"
                        )}
                      </div>
                    </div>
                  </>
                )}
                {page === 3 && (
                  <>
                    <textarea
                      name="runningAds"
                      placeholder="Are you currently running ads?"
                      value={formData.runningAds}
                      onChange={handleChange}
                      className="w-full p-2 border rounded focus:outline-none flex-grow"
                      rows="3"
                    />
                    <textarea
                      name="marketingBudget"
                      placeholder="What is the Budget You're investing for marketing?"
                      value={formData.marketingBudget}
                      onChange={handleChange}
                      className="w-full p-2 border rounded focus:outline-none flex-grow"
                      rows="3"
                    />
                    <textarea
                      name="marketingInvestment"
                      placeholder="How much budget have you set aside to invest in marketing for growing your business?"
                      value={formData.marketingInvestment}
                      onChange={handleChange}
                      className="w-full p-2 border rounded focus:outline-none flex-grow"
                      rows="3"
                    />
                    <textarea
                      name="customerAcquisition"
                      placeholder="How are you currently acquiring customers?"
                      value={formData.customerAcquisition}
                      onChange={handleChange}
                      className="w-full p-2 border rounded focus:outline-none flex-grow"
                      rows="3"
                    />
                  </>
                )}
              </form>
              <div className="flex justify-between space-x-4 mt-4">
                {page > 1 && (
                  <button
                    type="button"
                    onClick={handleBack}
                    className="w-full py-2 bg-primary-dark text-black rounded transition"
                  >
                    Back
                  </button>
                )}
                {page < 3 && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`w-full py-2 text-white rounded transition ${
                      isNextEnabled ? "bg-primary-dark hover:bg-green-600" : "bg-[#deeecf]  cursor-not-allowed"
                    }`}
                    disabled={!isNextEnabled}
                  >
                    Next
                  </button>
                )}
                {page === 3 && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={`w-full py-2 text-white rounded transition ${
                      isNextEnabled ? "bg-primary-dark hover:bg-green-600" : "bg-[#cce6b5] cursor-not-allowed"
                    }`}
                    disabled={!isNextEnabled}
                  >
                    {messageSent}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showConfetti && <Confetti numberOfPieces={4000} recycle={false} />}
    </div>
  );
};

export default Modal;
