import React from 'react';
import {
  googleLogo,
  amyraLogo,
  toiLogo,
  metaLogo,
  lumosLearningLogo,
  smsbhartiLogo,
  phixmanLogo,
  linkedinLogo,
  ck12Logo,
  glLogo,
  cfpLogo,
  h2tLogo,
} from '../imagesModule';

const WorkedWith = () => {
  return (
    <div className="working-with pt-[60px]">
      <div className="text-plus-images text-center mx-auto  overflow-hidden container">
        <div className="header-text font-avertastd-semibold text-fs-headers pb-[50px]">
          <h2>Worked With</h2>
        </div>
        <div className="working-with-images company-logos flex flex-wrap items-center justify-center sm:gap-[0.7em] xsm:gap-[0.1em]">
          <div className="logo logo1">
            <img
              className="opacity-40 sm:h-[90px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={googleLogo}
              alt=""
            />
          </div>
          <div className="logo logo2">
            <img
              className="opacity-40 sm:h-[80px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={amyraLogo}
              alt=""
            />
          </div>
          <div className="logo logo3">
            <img
              className="opacity-40 sm:h-[35px] xsm:h-[20px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={toiLogo}
              alt=""
            />
          </div>
          <div className="logo logo4">
            <img
              className="opacity-40 sm:h-[90px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={metaLogo}
              alt=""
            />
          </div>
          <div className="logo logo5">
            <img
              className="opacity-40 sm:h-[50px] xsm:h-[40px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={lumosLearningLogo}
              alt=""
            />
          </div>
          <div className="logo logo6">
            <img
              className="opacity-40 sm:h-[20px] xsm:h-[15px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={smsbhartiLogo}
              alt=""
            />
          </div>
          <div className="logo logo7">
            <img
              className="opacity-40 sm:h-[100px] xsm:h-[90px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={phixmanLogo}
              alt=""
            />
          </div>
          <div className="logo logo8">
            <img
              className="opacity-40 sm:h-[100px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={linkedinLogo}
              alt=""
            />
          </div>
          <div className="logo logo9">
            <img
              className="opacity-40 sm:h-[100px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={ck12Logo}
              alt=""
            />
          </div>
          <div className="logo logo10">
            <img
              className="opacity-40 sm:h-[100px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={glLogo}
              alt=""
            />
          </div>
          <div className="logo logo11">
            <img
              className="opacity-40 sm:h-[90px] xsm:h-[90px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={cfpLogo}
              alt=""
            />
          </div>
          <div className="logo logo12">
            <img
              className="opacity-40 sm:h-[90px] xsm:h-[80px] mx-3 my-1 sm:mx-3 xsm:my-1 lg:mx-4 lg:my-3"
              src={h2tLogo}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkedWith;
