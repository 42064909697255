import React from "react";

const ParagraphPdf = ({ data }) => {
  const { text, links } = data;

  if (!links || !Array.isArray(links) || links.length === 0) {
    return <p>{text}</p>;
  }

  // Sort links by their positions to avoid incorrect replacements
  const sortedLinks = links.sort((a, b) => text.indexOf(a.word) - text.indexOf(b.word));

  // Function to safely replace words with links
  const replaceWithLinks = (text, links) => {
    let result = [];
    let lastIndex = 0;

    links.forEach((link) => {
      const { word, url } = link;
      const linkIndex = text.indexOf(word, lastIndex);

      if (linkIndex !== -1) {
        // Push text before the link
        result.push(text.slice(lastIndex, linkIndex));
        // Push the link itself
        result.push(
          <a key={linkIndex} href={url} target="_blank" rel="noopener noreferrer" className="underline">
            {word}
          </a>
        );
        // Update the last index
        lastIndex = linkIndex + word.length;
      }
    });

    // Push any remaining text after the last link
    result.push(text.slice(lastIndex));
    return result;
  };

  return <p>{replaceWithLinks(text, sortedLinks)}</p>;
};

export default ParagraphPdf;
