import React from 'react';
// import { AiOutlineCloseCircle } from 'react-icons/ai';

// Add the functionality of the modal ---> closing of the modal when the cross is clicked and adding a transition
const AttentionModal = () => {
  return (
    <div className="bg-secondary-light flex justify-center items-center relative sm:h-[60px] xsm:h-[50px] sm:mx-[10px] xsm:mx-[5px] mt-[10px] rounded-[20px] mb-[20px]">
      <p className="font-avertastd-bold text-secondary-dark sm:text-2xl xsm:text-base">
        ATTENTION: Startup founders & Business Owners
      </p>

      {/* <AiOutlineCloseCircle className="text-secondary-dark absolute cursor-pointer sm:right-[8px] sm:top-[8px]  sm:text-icon-xl  xsm:text-icon-lg xsm:top-[20%] xsm:right-[10px]" /> */}
    </div>
  );
};

export default AttentionModal;
