import Hero from "../components/Hero";
import AboutMe from "../components/AboutMe";
import WorkedWith from "../components/WorkingWith";
import GetExpertTeam from "../components/GetExpertTeam";
import AttentionModal from "../components/AttentionModal";
import Products from "../components/Products";

const HomePage = () => {
  return (
    <div>
      <AttentionModal />
      <Hero />
      <AboutMe />
      <WorkedWith />
      <GetExpertTeam />
      <Products />
    </div>
  );
};

export default HomePage;
