import React from 'react';
import {
  herobg,
  mailToInternet,
  networking,
  personMic,
  statistics1,
  statistics2,
} from '../imagesModule';

const HeroBg = () => {
  return (
    <div className="background-image-hero-container right-side-hero lg:p-[10px] ">
      <div className="relative lg:w-[500px] sm:w-[450px]  xsm:w-[365px]">
        <img src={herobg} alt="" className="herobg-img w-[100%] drop-shadow-2xlgreen" />
        <div className="hero-elements-icons">
          <img
            src={networking}
            alt=""
            className="networking-img lg:h-[95px] sm:h-[90px] xsm:h-[85px] absolute lg:top-[150px] sm:top-[110px] xsm:top-[90px] lg:left-[80px] sm:left-[90px] xsm:left-[60px]"
          />
          <img
            src={personMic}
            alt=""
            className="personMic-img lg:h-[200px] sm:h-[170px] xsm:h-[150px] absolute lg:bottom-[70px] sm:bottom-[60px] xsm:bottom-[40px] lg:left-[30px] sm:left-[50px] xsm:left-[30px]"
          />
          <img
            src={statistics2}
            alt=""
            className="statistics2-img lg:h-[90px] sm:h-[80px] xsm:h-[70px] absolute lg:top-[70px] sm:top-[80px] xsm:top-[60px] lg:left-[210px] sm:left-[250px] xsm:left-[180px]"
          />
          <img
            src={statistics1}
            alt=""
            className="statistics1-img lg:h-[90px] sm:h-[80px] xsm:h-[70px] absolute lg:top-[200px] sm:top-[210px] xsm:bottom-[160px] lg:right-[40px] sm:right-[40px] xsm:right-[20px]"
          />
          <img
            src={mailToInternet}
            alt=""
            className="mailToInternet-img lg:h-[120px] sm:h-[110px] xsm:h-[100px] absolute lg:bottom-[60px] sm:bottom-[30px] xsm:bottom-[30px] lg:left-[230px] sm:left-[210px] xsm:right-[80px]"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroBg;
