import React, { useState } from "react";
import HeroBg from "./HeroBg";
import Modal from "./ContactModal";

export const CTABtn = ({ setIsOpen }) => {
  return (
    <div className="cta-button font-avertastd-semibold text-text-color text-fs-regular">
      <button
        onClick={() => setIsOpen(prev => !prev)}
        type="button"
        className="uppercase sm:bg-primary-light xsm:bg-primary-mid p-[20px] rounded-[15px]">
        BOOK A DISCOVERY CALL NOW !
      </button>
    </div>
  );
};

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="hero-container flex items-center justify-center flex-wrap text-text-color mt-[20px]">
      <div className="left-side-hero flex flex-col xsm:p-[10px] xsm:justify-center xsm:items-center lg:justify-start lg:items-start xsm:mb-[30px]">
        <div className="catchphrase uppercase font-avertastd-extrabold text-fs-accent leading-[1.2] xsm:text-center lg:text-left max-w-[650px]">
          <h1>
            Get <span className="text-primary-dark">5X-10X</span> ROI From Your{" "}
            <span className="text-primary-dark">Digital Marketing Ads</span>{" "}
          </h1>
        </div>
        <div className="description font-avertastd-regular text-fs-regular sm:max-w-[450px] xsm:max-w-[350px] xsm:text-center lg:text-left lg:max-w-[550px] my-[30px] text-text-color">
          <p>
            I help business owners with performance marketing that results
            actual sales, so they can focus on other aspects of their business
          </p>
        </div>
        <CTABtn setIsOpen={setIsOpen} />
        <Modal open={isOpen} onClose={() => setIsOpen(false)} />
      </div>
      <HeroBg />
    </div>
  );
};

export default Hero;
