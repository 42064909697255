import img1 from "../img/Avatar-1.png";
import img2 from "../img/Avatar-2.png";
const ProductCard = ({ title, description, price, points, img, redirect }) => {
  return (
    <div className="border-primary-dark border-[4px] border-solid rounded-xl p-5 flex flex-col w-full lg:w-1/3">
      <div className="flex flex-col justify-center items-center mb-4">
        <div className="rounded-full bg-primary-mid h-16 w-16 mb-2">
          <img src={img} alt="avatar" width="100%" height="100%" />
        </div>
        <h2 className="text-2xl font-avertastd-bold text-text-color mb-1">{title}</h2>
        <p className="font-avertastd-regular text-base text-text-color">{description}</p>
      </div>
      <h1 className="font-avertastd-bold text-fs-accent text-text-color text-center mb-4">₹{price}</h1>
      <div>
        {points.map(({ text, tick }, index) => (
          <div className="flex items-start max-w-[280px] mx-auto mb-2" key={index}>
            <div className="checkmark mr-1">
              <div className={`checkmark_stem__${tick ? "green" : "gray"}`}></div>
              <div className={`checkmark_kick__${tick ? "green" : "gray"}`}></div>
            </div>
            <p className="pl-2 font-avertastd-regular text-base text-text-color ">{text}</p>
          </div>
        ))}
      </div>
      <div className="text-center">
        <a href={redirect}>
          <button className="w-full sm:w-[150px] bg-primary-mid px-4 py-2 rounded-big font-avertastd-regular mx-auto mt-6 hover:bg-primary-dark focus:bg-primary-dark rounded-md">
            Buy now
          </button>
        </a>
      </div>
    </div>
  );
};

const Products = () => {
  const product1 = [
    {
      text: "Discussions with Experts",
      tick: true,
    },
    {
      text: "Interactive sessions",
      tick: true,
    },
    {
      text: "Doubt solving sessions",
      tick: true,
    },
    {
      text: "Rock solid concepts of EW",
      tick: true,
    },
    {
      text: "Unlimited access to future webinars",
      tick: true,
    },
    {
      text: "Lifetime access to Student Support Group",
      tick: true,
    },
  ];
  const product2 = [
    {
      text: "Doubt solving sessions",
      tick: true,
    },
    {
      text: "Interactive sessions",
      tick: true,
    },
    {
      text: "Start making money",
      tick: true,
    },
    {
      text: "Rock solid concepts of EW",
      tick: true,
    },
    {
      text: "Unlimited access to future webinars",
      tick: false,
    },
    {
      text: "Lifetime access to Student Support Group",
      tick: false,
    },
  ];
  return (
    <div className="container mx-auto px-4 mb-40">
      <div className="w-full big:w-2/3 lg:w-1/2 pr-6 mb-8">
        <h1 className="font-avertastd-extrabold text-fs-accent leading-[1.2] text-text-color">
          Simple and Transparent Pricing
        </h1>
        <p className="font-avertastd-regular text-base text-text-color mt-4">
          Explore our flexible pricing plans designed to suit a variety of needs and budgets. Choose the plan that best
          fits your requirements and enjoy premium features at affordable rates.
        </p>
      </div>
      <div className="flex flex-col big:flex-row gap-6 justify-center items-center">
        <ProductCard
          title="Mentorship"
          description="Great for Personal Progress Tracking"
          price="80,000"
          points={product1}
          img={img1}
          redirect="https://marketingwithram.in/wp/mentorship-final-checkout/"
        />
        <ProductCard
          title="Online Training"
          description="Great for Beginners"
          price="40,000"
          points={product2}
          img={img2}
          redirect="https://marketingwithram.in/wp/online-training-final-checkout/"
        />
      </div>
    </div>
  );
};

export default Products;
