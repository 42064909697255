import React from "react";

const replaceWithLinks = (text, links) => {
  let result = [];
  let lastIndex = 0;

  if (links !== undefined) {
    links.forEach((link, index) => {
      const { word, url } = link;
      const linkIndex = text.indexOf(word, lastIndex);

      if (linkIndex !== -1) {
        // Push text before the link
        result.push(text.slice(lastIndex, linkIndex));
        // Push the link itself
        result.push(
          <a key={`${url}-${index}`} href={url} target="_blank" rel="noopener noreferrer" className="underline">
            {word}
          </a>
        );
        // Update the last index
        lastIndex = linkIndex + word.length;
      }
    });
  }

  // Push any remaining text after the last link
  result.push(text.slice(lastIndex));
  return result;
};

// Recursive List component
const List = ({ items, level = 1, start, links }) => {
  if (!items || items.length === 0) {
    return null;
  }

  const listType = level === 1 ? "ol" : "ul";

  return React.createElement(
    listType,
    { start, style: { marginLeft: "40px", listStyleType: level === 1 ? "decimal" : "disc" } },
    items.map((item, index) => (
      <React.Fragment key={index}>
        <li>
          {replaceWithLinks(item.text, links)}
          {item.children && <List items={item.children} level={level + 1} start={1} links={links} />}
        </li>
      </React.Fragment>
    ))
  );
};

// SubSectionsPdf component
const SubSectionsPdf = ({ data }) => {
  let listCounter = 1; // Counter to track the list item numbering

  const renderItem = (item, index) => {
    if (item.isText) {
      listCounter = 1; // Reset the counter for non-list items
      return <p key={index}>{replaceWithLinks(item?.text, data?.links)}</p>;
    } else {
      const currentListCounter = listCounter;
      listCounter += 1; // Increment the counter for each list item
      return <List key={index} items={[item]} start={currentListCounter} links={data.links} />;
    }
  };

  return (
    <div>
      <h2 className="font-avertastd-semibold text-fs-headers">{data?.title}</h2>
      {data.description && <p>{replaceWithLinks(data.description, data.links)}</p>}
      {data.items && data.items.map((item, index) => renderItem(item, index))}
    </div>
  );
};

export default SubSectionsPdf;
