import React from 'react';

const AboutMe = () => {
  return (
    <div className="about-me mt-[80px]">
      <div className="about-me-texts flex justify-center items-center flex-col">
        <div className="header-text font-avertastd-semibold text-fs-headers text-center">
          <h2>About Me</h2>
        </div>
        <div className="description-text font-avertastd-regular text-fs-regular text-center container p-[5px]">
          <p>
            I am a Performance Marketing Professional with 6 Years of experience. I have worked at Lumos Learning, Great Learning & CampK12. I help business increase their Sales while Reducing the Acqusition Costs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
