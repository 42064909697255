import HeaderPdf from "../components/policy-components/Header";
import ParagraphPdf from "../components/policy-components/Paragraph";
import SubSectionsPdf from "../components/policy-components/SubSections";

const TNCPageData = {
  header: "Terms & Conditions",
  pg1: {
    text: "MARKETING WITH RAM is managed by VYRAL24 ADVERTISING AND MARKETING SERVICES PVT LTD. MARKETINGWITHRAM.in is committed to respecting the privacy rights of our customers, visitors, and other users of our services provided via our website and/or call center. We are committed to fair information practices and the protection of privacy. This Privacy Policy is only applicable to our site, and not to any other websites that you may be able to access from our site, including our partners' sites, each of which may have data collection, storage, and use practices and policies that may differ significantly from this Privacy Policy. Your use of the site is governed by this Privacy Policy.",
    links: [
      {
        url: "https://marketingwithram.in",
        word: "MARKETINGWITHRAM.in",
      },
      {
        url: "https://marketingwithram.in",
        word: "MARKETING WITH RAM",
      },
      {
        url: "https://vyral24.com",
        word: "VYRAL24 ADVERTISING AND MARKETING SERVICES PVT LTD",
      },
    ],
  },
  subsection1: {
    title: "Data Collected",
    description: "During our services, we may collect the following information",
    items: [
      {
        text: "Traffic Data: The following categories of information are tracked and captured when you visit our Website:",
        children: [
          { text: "IP addresses;" },
          { text: "domain servers;" },
          { text: "types of computers accessing the Website;" },
          { text: "types of web browsers used to access the Website;" },
          { text: "referring source which may have sent you to the Website;" },
          { text: "other information associated with the interaction of your browser and the Website." },
        ],
      },
      {
        text: "This information is used for statistical reasons and not stored with your personal information.",
        isText: true,
      },
      {
        text: "Personal Information: For you to use certain features of the site, such as booking an appointment, we require you to register, which contains your personal information such as your e-mail address, phone number, name, date of birth, etc.",
        children: [
          {
            text: "We share this information with the Healthcare provider to help the healthcare provider maintain a history against the patient profile that helps in the future diagnosis and treatment of the patient's health concerns.",
          },
        ],
      },
      {
        text: "Medical Data: Any medical data, such as your reason for visit, your date of visit, and other medical information you choose to share with us, is shared with the healthcare provider to help in the diagnosis and treatment of the health issue.",
      },
      {
        text: "Transactional Data: All transactional data, such as searches for healthcare providers, your appointments, number of visits, any diagnosis, prescriptions, etc is used to ensure a better service.",
      },
      {
        text: "Cookie Data: Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow it) that enable the sites or service providers' systems to recognize your browser and capture and remember certain information. We use cookies to help us understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function properly. However, you can still book appointments over the telephone by calling (022) 61645300.",
      },
      {
        text: "Web Analytics: We may use third-party web analytics services to track and analyze your interaction with the site, such as mouse clicks, mouse movements, scrolling activity as well as any text that you choose to type into the Website. These analytics services do not collect Personal Information that you do not voluntarily enter into the site. We use this information to enhance your experience.",
      },
    ],
  },
  subsection2: {
    title: "Use of Your Information",
    description:
      "We use your contact information primarily to contact you when necessary, including to remind you of upcoming or follow-up appointments. In certain cases, we may use your contact information to send you information about any new or relevant services. We may also use your demographic data, your traffic data, or your medical data to customize and tailor your experience on the site, and for usage of medical services through your chosen healthcare provider. You agree that we may use your personal information to allow your chosen healthcare providers to make appointments with other healthcare providers on your behalf through our services.",
  },
  subsection3: {
    title: "Confidentiality and Security",
    description:
      "Except as otherwise provided in this Privacy Policy, we will keep your personal information private and will not share it with third parties, unless we believe in good faith that disclosure of your personal information or any other information we collect about you is necessary to:",
    links: [{ word: "marketingwithram.in", url: "https://marketingwithram.in" }],
    items: [
      { text: "comply with a court order or other legal process;" },
      { text: "protect the rights, property, or safety of marketingwithram.in or another party;" },
      { text: "enforce our Terms of Use or" },
      { text: "respond to claims that any posting or other content violates the rights of third parties." },
    ],
  },
  subsection4: {
    title: "Healthcare Provider",
    description:
      "Doctors and other healthcare providers, their employees, and their agents should be particularly aware of their obligations to patient confidentiality. marketingwithram.in does not have, and will not accept, any obligations of confidentiality concerning any communications other than those expressly stated in this Privacy Policy.",
    links: [{ word: "marketingwithram.in", url: "https://marketingwithram.in" }],
  },
  subsection5: {
    title: "Public Information",
    description:
      "Any information that you may reveal in a review posting or other online discussion or forum is intentionally open to the public and is not in any way private. You should think carefully before disclosing any personally identifiable information in any public forum. What you have written may be seen and/or collected by third parties and may be used by others in ways we are unable to control or predict.",
  },
  subsection6: {
    title: "Updates and Changes to Privacy Policy",
    description:
      "We reserve the right, at any time, to add to, change, update, or modify this Privacy Policy so please review it frequently. If we do, then we will notify you here, as well as by posting a notice on our site and/or by e-mailing you, along with a description of any changes (material or otherwise) and, where appropriate, a link to the modified policy so that you can review it. In all cases, the use of the information we collect is subject to the Privacy Policy in effect at the time such information is collected.",
  },
  subsection7: {
    title: "Contact Us",
    description:
      "If there are any questions regarding this privacy policy you may contact us using the information below.",
    items: [{ text: "team@marketingwithram.in", isText: true }],
  },
};

const TermsNConditionsPage = () => {
  return (
    <div className="container px-4 min-h-[85vh]">
      <HeaderPdf data={TNCPageData.header} />
      <ParagraphPdf data={TNCPageData.pg1} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection1} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection2} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection3} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection4} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection5} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection6} />
      <div className="mt-8" />
      <SubSectionsPdf data={TNCPageData.subsection7} />
    </div>
  );
};

export default TermsNConditionsPage;
