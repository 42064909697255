import HeaderPdf from "../components/policy-components/Header";
import ParagraphPdf from "../components/policy-components/Paragraph";

const CUPageData = {
  header: "Contact Us",
  pg1: {
    text: "Email Address: team@marketingwithram",
  },
  pg2: {
    text: "Support Mobile:+91 9205252399",
  },
  pg3: {
    text: "Regd Address: Flat-200, Studio Apartments, Sector-16B, Dwarka, New Delhi, Pin Code-110078",
  },
  pg4: {
    text: "Corporate Office: Magnum Tower 1, 8th Floor, Golf Course Ext Rd, Sector 58, Gurugram, Haryana 122011",
  },
};

const ContactUsPage = () => {
  return (
    <div className="container px-4 min-h-[85vh]">
      <HeaderPdf data={CUPageData.header} />
      <ParagraphPdf data={CUPageData.pg1} />
      <div className="mt-5" />
      <ParagraphPdf data={CUPageData.pg2} />
      <div className="mt-5" />
      <ParagraphPdf data={CUPageData.pg3} />
      <div className="mt-5" />
      <ParagraphPdf data={CUPageData.pg4} />
      <div className="w-[100%] lg:h-[450px] sm:h-[375px] sm:aspect-[4/3] aspect-auto md:pt-0 pt-10 min-h-[350px]">
        <iframe
          title="contact-us"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3509.244817811212!2d77.1065502!3d28.4118688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d21f3f7985915%3A0x7842fc32eac01c23!2sMAGNUM%20TOWERS!5e0!3m2!1sen!2sin!4v1712595005110!5m2!1sen!2sin"
          style={{ border: 0, height: "100%", width: "100%" }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUsPage;
