import HeaderPdf from "../components/policy-components/Header";
import ParagraphPdf from "../components/policy-components/Paragraph";
import SubSectionsPdf from "../components/policy-components/SubSections";

const RPPageData = {
  header: "Cancellation and Refund Policy",
  pg1: {
    text: "Thanks for your interest in marketingwighram.in products/services. We ensure to provide an excellent experience and learning to all our users. As with any online purchase experience, some terms and conditions govern the Refund Policy. When you purchase a program on marketingwithram.in under Ramkrishnan Muduli Proprietorship, you agree to our Terms & Conditions and refund policy.",
    links: [
      {
        url: "https://marketingwithram.in",
        word: "marketingwithram.in",
      },
    ],
  },
  subsection1: {
    title: "Our refund policy is as follows:",
    description: `We do not provide refunds for Digital Products/Services purchases. You acknowledge that we are under no obligation to refund any fees and applicable charges paid by you, in full or partially, under no circumstances, including for modifying and extending the duration of the Service, change in the commencement date of the Service, your failure to attend or participate in the Service, modification of the structure or content of the Service. We don't offer a "no questions asked refund policy." We only have "execution based result oriented, refund policy." We don't provide refunds for duplicate purchases or for any by mistake purchases. Please be careful while making payments. We would not entertain any such reason for refund. Strictly, no refund policy because of the nature of the deliverables & the VALUE OF THE DELIVERABLES. For products like events, you can transfer your ticket to someone else atleast a 3-days prior to the event by contacting us at team@marketingwithram.in`,
  },
  subsection2: {
    title: "Only under one condition the refund is provided, for Digital Products/Services:",
    description: `If you apply everything which is taught within the product/service which you have purchased & if you don't get any significant practical results, you can reach out to our us at team@marketingwithram.in. We will ask you for the following details: Your product/service, your landing page, your real ads, your market research, how much did you spend on Meta ads using some frameworks or framework & how many days did you follow it. After reviewing everything, if we feel that all your executions are on point & then it didn't work for your business, only then we shall initiate a refund for your purchase. If a refund is provided, you hereby agree and acknowledge that such amount to be refunded may either be paid in cash or credit within 5-10 business days (for purchase of any other Services of marketingwithram.in productions of equivalent value), at the sole discretion of Ramkrishnan Muduli Proprietorship.`,

  },
};

const RefundPolicyPage = () => {
  return (
    <div className="container px-4 min-h-[85vh]">
      <HeaderPdf data={RPPageData.header} />
      <ParagraphPdf data={RPPageData.pg1} />
      <div className="mt-8" />
      <SubSectionsPdf data={RPPageData.subsection1} />
      <div className="mt-8" />
      <SubSectionsPdf data={RPPageData.subsection2} />
    </div>
  );
};

export default RefundPolicyPage;
