import { Route, BrowserRouter, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import Footer from "./components/Footer";
import NotFoundPage from "./pages/NotFound";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import TermsNConditionsPage from "./pages/TermsNConditions";
import RefundPolicyPage from "./pages/RefundPolicy";
import ShippingPolicyPage from "./pages/ShippingPolicy";
import ContactUsPage from "./pages/ContactUs";

function App() {
  return (
    <div className="App bg-background-color">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-condition" element={<TermsNConditionsPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          {/* <Route path="/shipping-policy" element={<ShippingPolicyPage />} /> */}
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}
export default App;
