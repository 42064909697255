import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex justify-center items-center flex-col">
      <h1 className="text-fs-accent">Not Found !</h1>
      <h3 className="text-fs-regular">This route does not exist. Try different URL.</h3>
    </div>
  );
};

export default NotFoundPage;
