import React, { useEffect, useState } from "react";
import { CTABtn } from "./Hero";
import { semTeam, creativeTeam, smTeam } from "../imagesModule";
import Modal from "./ContactModal";

const TeamCard = ({ title, source, description }) => {
  return (
    <div className="relative container team-card sm:max-w-[380px] xsm:w-[300px] sm:h-[340px] xsm:h-[310px] rounded-[25px] bg-white  shadow-card flex flex-col justify-evenly items-center mx-auto xsm:mb-32 lg:mt-0">
      <div className="team-card-img w-[85%] relative">
        <img
          className="w-full bottom-[-50px] absolute drop-shadow-2xl"
          src={source}
          alt=""
        />
      </div>
      <div className="team-card-text ">
        <div className="team-card-title text-center font-avertastd-semibold underline text-fs-card-header">
          <h2>{title}</h2>
        </div>
        <div className="team-card-description text-center font-avertastd-regular text-fs-card-des leading-[1.1] p-[11px]">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const GetExpertTeam = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const mobileWidth = 550;
  useEffect(() => {
    // Add event listener to update screen size when the window is resized
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", function () {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <div className="expert-teams flex justify-center items-center flex-wrap flex-col mt-32  container mx-auto mb-[4em]">
      <div className="header-text get-expert-teams-header text-fs-headers font-avertastd-semibold mb-[8em] text-center">
        <h1>
          Get <span className="text-primary-dark"> 3 EXPERT</span> Led{" "}
          <span className="text-primary-dark">TEAMS !</span>{" "}
        </h1>
      </div>
      <div className="expert-teams-cards flex flex-wrap  w-full mt-8">
        <TeamCard
          source={semTeam}
          title="SEM Team"
          description="Boost online presence. Drive traffic & conversions. Optimize campaigns for success. SEM team excels in digital advertising prowess."
        />
        <TeamCard
          source={smTeam}
          title="Paid Social Team"
          description="Creative minds at work: crafting captivating visuals, innovative designs, and compelling content that resonates with audiences."
        />
        <TeamCard
          source={creativeTeam}
          title="Creative Team"
          description="Maximizing social impact. Strategic ad campaigns for targeted audiences. Paid social team drives engagement and growth."
        />
      </div>
      {screenWidth <= mobileWidth ? <CTABtn setIsOpen={setIsOpen} /> : <></>}
      {screenWidth <= mobileWidth ? (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default GetExpertTeam;
